import { Link } from '@shared-ui/components'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { NotificationBase, NotificationBaseItem, Text } from '@ubnt/ui-components'

export function ConnectivityIssue() {
  return (
    <div>
      <NotificationBase variant="secondary">
        <ExclamationMarkIcon size="large" />

        <NotificationBaseItem>
          <Text size="body">
            Client connectivity, roaming, and overall stability are significantly improved on the 8.0.9 firmware,
            currently in Early Access.
          </Text>

          <Text size="body" className="pt-12">
            To follow release updates click <Link href="https://community.ui.com/releases/r/uap/8.0.9">here</Link>.
          </Text>
        </NotificationBaseItem>
      </NotificationBase>
    </div>
  )
}
