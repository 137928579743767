import type { NetsuiteProductId } from '@shared/types/brands'
import { UbiquitiLogo } from '@ubnt/icons'
import type { DropdownOption } from '@ubnt/ui-components'
import { Dropdown } from '@ubnt/ui-components'
import { useEffect, useState } from 'react'
import { useRequest } from '../hooks'
import { MultiDropdown } from './multi-dropdown'

export type ProductSearchType = 'core' | 'all'

type ProductOption = DropdownOption & { categoryId?: string }

interface ProductSelected {
  value: string
  image: string
  label: string
}

interface ProductsDropdownProps {
  type?: ProductSearchType
  initialValue?: string
  onChange?: (option: ProductSelected) => void
  onChangeMulti?: (options: NetsuiteProductId[]) => void
  onError?: (error?: string) => void
  onLoading?: (isLoading: boolean) => void
  omitProductIds?: NetsuiteProductId[]
  label?: string
  placeholder?: string
  multi?: boolean
  variant?: 'default' | 'secondary'
  readOnly?: boolean
}

const START_TYPING = 'Type to find your product.'

let idCounter = 0

export function ProductsDropdown({
  type = 'all',
  initialValue,
  onChange,
  onChangeMulti,
  onError,
  onLoading,
  omitProductIds,
  label,
  placeholder = 'All products',
  multi,
  variant = 'default',
  readOnly = false,
}: ProductsDropdownProps) {
  const [search, setSearch] = useState('')
  const [emptyMessage, setEmptyMessage] = useState(START_TYPING)
  const [products, setProducts] = useState<ProductOption[] | undefined>(undefined)
  const [productIds, setProductIds] = useState<NetsuiteProductId[]>([])
  const [showInitialValue, setShowInitialValue] = useState(!!initialValue)

  const [getProducts, { loading, error }] = useRequest(type === 'core' ? 'coreProductsByName' : 'productsByName', {
    delay: 200,
  })

  useEffect(() => {
    async function doFetch() {
      const trim = search.trim()

      if (!trim) {
        setEmptyMessage(START_TYPING)
        return
      }

      try {
        setEmptyMessage('Loading...')
        setProducts(undefined)

        const data = await getProducts({
          search: trim,
        })
        const options = data.products
          .map<ProductOption>((product) => {
            idCounter += 1

            return {
              label: product.name,
              value: `${product.id}.${idCounter}`, // FIX: temporary workaround for ui-components dropdown duplicate id bug
              image: product.iconUrl || <UbiquitiLogo variant="neutral" size="logo" />,
              categoryId: product.rmaParentCategoryId,
            }
          })
          .filter(({ value }) => !omitProductIds || !omitProductIds.includes(String(value)))

        if (!options.length) {
          setEmptyMessage('No results found.')
        }

        setProducts(options)
        setShowInitialValue(false)
      } catch (err) {
        //
      }
    }

    void doFetch()
  }, [search])

  useEffect(() => {
    if (onError) {
      onError(error)
    }
  }, [error, onError])

  useEffect(() => {
    if (onLoading) {
      onLoading(loading)
    }
  }, [loading, onLoading])

  if (multi) {
    return (
      <Dropdown
        label={label}
        searchable
        multi
        searchEmptyMessage={emptyMessage}
        width="100%"
        variant={variant}
        autoCorrect="false"
        autoCapitalize="false"
        placeholder={placeholder}
        data-testid="all-products-search"
        value={productIds}
        options={search.trim() ? products : []}
        clearable
        readOnly={readOnly}
        onInputChange={(_, value) => {
          setSearch(value.toString())
        }}
        onChange={(option, values) => {
          const newProductIds = values as NetsuiteProductId[]
          setProductIds(newProductIds)

          if (onChangeMulti) {
            onChangeMulti(newProductIds.map((entry) => entry.split('.')[0]))
          }
          if (onChange) {
            const productOption = option as ProductOption
            const productId = String(productOption.value)
            const value = typeof productId === 'string' ? productId.split('.')[0] : productId

            onChange({
              value,
              image: String(productOption.image) || '',
              label: productOption.label,
            })
          }
        }}
        fontSize="body"
        portal
      />
    )
  }

  const currOptions = search.trim() ? products : []

  return (
    <Dropdown
      label={label}
      searchable
      searchEmptyMessage={emptyMessage}
      width="100%"
      autoCorrect="false"
      autoCapitalize="false"
      placeholder="All products"
      data-testid="all-products-search"
      variant={variant}
      readOnly={readOnly}
      value={showInitialValue ? initialValue : productIds[0]}
      options={showInitialValue ? [{ value: initialValue || '', label: initialValue || '' }] : currOptions}
      onInputChange={(_e, value) => {
        setSearch(value.toString())
      }}
      onChange={(option: ProductOption, id) => {
        const newProductIds = [id] as NetsuiteProductId[]
        setProductIds(newProductIds)
        setShowInitialValue(false)

        if (onChangeMulti) {
          onChangeMulti(newProductIds.map((entry) => entry.split('.')[0]))
        }

        if (onChange) {
          const productOption = option
          const productId = String(productOption.value)
          const value = typeof productId === 'string' ? productId.split('.')[0] : productId

          onChange({
            value,
            image: String(productOption.image) || '',
            label: productOption.label,
          })
        }
      }}
      fontSize="body"
      portal
    />
  )
}

interface MultiProductsDropdownProps {
  label?: React.ReactNode | string
  value?: DropdownOption[]
  placeholder?: string
  disabled?: boolean
  onChange?: (products: DropdownOption[]) => void
  onError?: (error?: string) => void
  onLoading?: (isLoading: boolean) => void
}

export function MultiProductsDropdown({
  label,
  value,
  placeholder,
  disabled,
  onChange,
  onError,
  onLoading,
}: MultiProductsDropdownProps) {
  const [search, setSearch] = useState('')
  const [products, setProducts] = useState<DropdownOption[] | undefined>(undefined)
  const [emptyMessage, setEmptyMessage] = useState(START_TYPING)
  const [getProducts] = useRequest('coreProductsByName', {
    delay: 200,
  })
  const [firstLoading, setFirstLoading] = useState(true)

  async function doFetch() {
    const trim = search.trim()

    if (!trim) {
      setEmptyMessage(START_TYPING)
      return
    }

    try {
      setEmptyMessage('Loading...')
      setProducts(undefined)

      if (onLoading) {
        onLoading(true)
      }

      const data = await getProducts({
        search: trim,
      })
      const options = data.products.map<DropdownOption>((product) => {
        return {
          label: product.name,
          value: product.id,
        }
      })

      setProducts(options)

      if (onLoading) {
        onLoading(false)
      }

      if (options.length <= 0) {
        setEmptyMessage('No results found.')
      }
    } catch (err) {
      if (onError) {
        onError(err instanceof Error ? err.message : 'Unknown Error')
      }
    }
  }

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false)
      return
    }

    void doFetch()
  }, [search])

  return (
    <MultiDropdown
      value={value}
      label={label}
      placeholder={placeholder}
      searchable
      disabled={disabled}
      options={products}
      optionsPlaceholder={emptyMessage}
      onChange={(options) => {
        if (onChange) {
          onChange(options)
        }
      }}
      onInputChange={(newInputValue) => {
        setSearch(newInputValue)
        if (!newInputValue) {
          setProducts([])
        }
      }}
    />
  )
}

interface MultiProductSkusDropdownProps extends MultiProductsDropdownProps {
  coreProducts: string[]
}

export const MultiProductSkusDropdown = ({
  label,
  value,
  coreProducts,
  placeholder,
  disabled,
  onChange,
  onError,
  onLoading,
}: MultiProductSkusDropdownProps) => {
  const [options, setOptions] = useState<DropdownOption[] | undefined>(undefined)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [getProducts] = useRequest('coreProductsSkus', {
    delay: 200,
  })
  const [prevCoreProducts, setPrevCoreProducts] = useState(coreProducts)

  async function doFetch() {
    if (coreProducts.length <= 0) {
      setOptions(undefined)
      setPrevCoreProducts(coreProducts)
      setEmptyMessage('No results found.')
      if (onChange) {
        onChange([])
      }
      return
    }

    try {
      setEmptyMessage('Loading...')
      setOptions(undefined)

      if (onLoading) {
        onLoading(true)
      }
      setIsFetching(true)

      const data = await getProducts({
        productIds: coreProducts,
      })
      const newOptions = data.products.map<DropdownOption>((product) => {
        return {
          label: product.name,
          value: product.id,
        }
      })

      const removedProducts = prevCoreProducts
        .filter((entry) => !coreProducts.includes(entry))
        .map((entry) => entry.toLocaleLowerCase())

      const filteredValues = (value || []).filter((entry) => {
        const lowerCaseSku = entry.label.toLocaleLowerCase()
        for (const removedProduct of removedProducts) {
          if (lowerCaseSku.indexOf(removedProduct) !== -1) {
            return false
          }
        }

        return true
      })

      if (onChange) {
        const addedProducts = coreProducts
          .filter((entry) => !prevCoreProducts.includes(entry))
          .map((entry) => entry.toLocaleLowerCase())

        const newSkuOptions = newOptions.filter((entry) => {
          const lowerCaseSku = entry.label.toLocaleLowerCase()
          for (const addedProduct of addedProducts) {
            if (lowerCaseSku.indexOf(addedProduct) !== -1) {
              return true
            }
          }

          return false
        })

        const newValues = [...filteredValues, ...newSkuOptions]
        onChange(newValues)
      }

      setEmptyMessage('')
      setOptions(newOptions)
      setPrevCoreProducts(coreProducts)

      if (onLoading) {
        onLoading(false)
      }
      setIsFetching(false)
    } catch (err) {
      if (onError) {
        onError(err instanceof Error ? err.message : 'Unknown Error')
      }
    }
  }

  useEffect(() => {
    if (coreProducts === prevCoreProducts) {
      return
    }

    void doFetch()
  }, [coreProducts])

  return (
    <MultiDropdown
      value={value}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      options={options}
      loading={isFetching}
      optionsPlaceholder={emptyMessage}
      onChange={(newOptions) => {
        if (onChange) {
          onChange(newOptions)
        }
      }}
    />
  )
}
