import { Button, FailureCategory, FileUpload, Link, TextArea } from '@shared-ui/components'
import { TICKET_FAILURE_DESCRIPTION_MAX_LENGTH } from '@shared/tickets'
import type { NetsuiteFailureCategoryId } from '@shared/types/brands'
import { Text } from '@ubnt/ui-components'
import { useEffect } from 'react'
import { useSubmitRma } from '../../context/SubmitRma'
import { SubmitRmaService } from '../../submit-rma-service'
import { useSubmitRmaStore } from '../../submit-rma-store'
import { ConnectivityIssue } from '../issues/connectivity-issue'
import { RebootIssueDropdown } from '../issues/reboot-issue-dropdown'
import { StepHeading } from '../step-heading'
import { SubmitContainer } from '../submit-container'
import { UCareGradient } from '../u-care'
import { UCareBadge } from '../u-care-badge'
import { useValidateFilesLazyQuery as useValidateFilesLazy } from './__generated__/SelectSeller'

export function DescribeIssue() {
  const { device } = useSubmitRmaStore()

  const [validateFiles, { loading, data, error }] = useValidateFilesLazy({ fetchPolicy: 'no-cache' })

  const {
    failureCategory,
    setFailureCategory,
    rebootIssue,
    problemDescription,
    setProblemDescription,
    otherAttachmentFiles,
    otherAttachmentFilesPrev,
    setOtherAttachmentFiles,
  } = useSubmitRma()

  useEffect(() => {
    if (data?.validateFiles) {
      SubmitRmaService.next()
    }
  }, [data])

  const showRebootIssueDropdown =
    failureCategory &&
    (failureCategory.id === '310' ||
      (device?.coreItemCode &&
        ['U7-Pro', 'U7-Pro-Max'].includes(device.coreItemCode) &&
        ['210', '212', '216'].includes(failureCategory.id)))

  const showConnectivityIssue =
    failureCategory &&
    device?.coreItemCode &&
    ['U7-Pro', 'U7-Pro-Max'].includes(device.coreItemCode) &&
    ['311', '312'].includes(failureCategory.id)

  const nextDisabled =
    !failureCategory?.id ||
    !problemDescription ||
    (showRebootIssueDropdown && !rebootIssue) ||
    (otherAttachmentFiles && (otherAttachmentFiles.length > 5 || otherAttachmentFiles.some((file) => !!file.error)))

  const handleNext = () => {
    try {
      useSubmitRma.setState({
        otherAttachmentFilesPrev: otherAttachmentFiles,
      })

      const files = otherAttachmentFiles ? otherAttachmentFiles.map(({ file }) => file) : []
      validateFiles({ variables: { files, filesOptional: [] } })
    } catch (err) {
      //
    }
  }

  const handleBack = () => {
    SubmitRmaService.back()
    if (device?.isHandledByUbiquiti) {
      SubmitRmaService.back()
    }
  }

  return (
    <SubmitContainer>
      {device?.isUnifiCare && (
        <>
          <UCareGradient />
          <UCareBadge />
        </>
      )}

      <div className="flex column relative p-16 mt-6 gap-24">
        <StepHeading heading="What's the trouble?" />

        <FailureCategory
          customLabel="Issue Category"
          required
          sku={device?.coreItemCode}
          failureCategoryId={failureCategory?.id}
          onChange={(option) => {
            setFailureCategory({ id: option.value as NetsuiteFailureCategoryId, name: option.label })
          }}
        />

        {showRebootIssueDropdown && (
          <div className="flex column">
            <RebootIssueDropdown />
          </div>
        )}

        {showConnectivityIssue && (
          <div className="flex column">
            <ConnectivityIssue />
          </div>
        )}

        <TextArea
          customLabel="Description"
          required
          variant="secondary"
          value={problemDescription}
          placeholder="Give us little more detail of issue"
          maxLength={TICKET_FAILURE_DESCRIPTION_MAX_LENGTH}
          onChange={(e) => setProblemDescription(e.currentTarget.value)}
        />

        <FileUpload
          label="Images"
          files={otherAttachmentFiles}
          fileType="images"
          setFiles={setOtherAttachmentFiles}
          maxFiles={5}
        />

        {error && otherAttachmentFiles === otherAttachmentFilesPrev && (
          <Text size="body" color="danger">
            Your file could not be validated. Please take a screenshot of the file’s information and upload it instead.
            If the issue persists, contact us at <Link href="mailto:rma@ui.com">rma@ui.com</Link> for assistance.
          </Text>
        )}

        <div className="flex justify-end gap-8">
          <Button variant="tertiary" onClick={handleBack}>
            Back
          </Button>

          <Button variant="primary" disabled={nextDisabled} onClick={handleNext} loader={loading ? 'dots' : undefined}>
            Next
          </Button>
        </div>
      </div>
    </SubmitContainer>
  )
}
