import NotificationsSidebar from '@shared-ui/components/notifications/NotificationsSidebar'
import { DetailsTab, LogTab, MessagingTab, Ticket as TicketShared, useTicketStore } from '@shared-ui/components/Ticket'
import type { Shared_Ticket_DetailsFragment } from '@shared-ui/components/Ticket/__generated__/Details'
import { useNestedPath } from '@shared-ui/hooks'
import type { TicketId } from '@shared/types/brands'
import { EscalatedType } from '@shared/types/tickets'
import { StarIcon } from '@ubnt/icons'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTicketQuery } from './__generated__/Ticket'
import { Rate } from './Ticket/Rate'

export const RateTab = {
  id: 'rate',
  path: '/rate',
  icon: <StarIcon size="navigation" />,
  component: Rate,
}

export interface UrlParam {
  sidebarParam: string
}

interface SidebarProps {
  onClose: () => void
}

const RmaDetailsTab = {
  ...DetailsTab,
  props: {
    canCancelTicket: true,
  },
}

export default function Sidebar({ onClose }: SidebarProps) {
  const { sidebarParam } = useParams<UrlParam>()

  if (sidebarParam === 'notifications') {
    return <NotificationsSidebar onClose={onClose} />
  }

  return <TicketSidebar ticketId={sidebarParam} onClose={onClose} />
}

export function shouldShowMessageTab(ticket?: Shared_Ticket_DetailsFragment | null) {
  if (!ticket) {
    return false
  }

  const shouldShow = ticket.supportByUbiquiti === false || ticket.offerUiSupport.type === EscalatedType.AlreadyDisputed

  return shouldShow
}

function TicketSidebar({ ticketId, onClose }: { ticketId: TicketId; onClose: () => void }) {
  const { data, loading, error, refetch } = useTicketQuery({
    variables: { id: ticketId },
  })

  const [redirectUrl, setRedirectUrl] = useState('')

  const nested = useNestedPath()
  const history = useHistory()

  const isLoading = loading

  function refetchTicket() {
    void refetch().then((newTicket) => {
      const redirectToMessaging = shouldShowMessageTab(newTicket.data?.ticket)
      if (redirectToMessaging) {
        setRedirectUrl('/messaging')
      }
    })
  }

  useEffect(() => {
    useTicketStore.getState().reset()
  }, [ticketId])

  useEffect(() => {
    if (redirectUrl) {
      history.push(`${nested(redirectUrl)}`)
    }
  }, [redirectUrl])

  const tabs = useMemo(() => {
    const showMessagingTab = shouldShowMessageTab(data?.ticket)

    return [RmaDetailsTab, LogTab, showMessagingTab ? MessagingTab : null, RateTab].filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
      <T extends unknown>(tab: T): tab is NonNullable<T> => !!tab,
    )
  }, [data])

  return (
    <TicketShared
      tabs={tabs}
      loading={isLoading}
      error={error}
      ticket={data?.ticket ?? undefined}
      refetchTicket={() => void refetchTicket()}
      onClose={onClose}
    />
  )
}
