import type { CountryCode } from 'libphonenumber-js/min'
import { getCountries } from 'libphonenumber-js/min'
import CountriesNames from './countriesNames'

const countryCodes = Object.entries(CountriesNames).reduce((acc, pair) => {
  acc[pair[0]] = pair[1]
  return acc
}, {} as Record<string, string>)

export { CountryCode }

export const isUnitedStates = (countryCode?: CountryCode) => countryCode && countryCode === 'US'

export const isEurope = (countryCode: CountryCode) => EuropeCountries.includes(countryCode as CountryCode)

export const isEuropeanUnion = (countryCode: CountryCode) => EuropeanUnionCountries.includes(countryCode as CountryCode)

export const haveStatesEnabled = (countryCode: CountryCode): boolean => countryCode === 'US' || countryCode === 'CA'

export const getCountryName = (code: CountryCode | string | null | undefined) => {
  return CountriesNames[code as CountryCode] || null
}

export const getCountryCode = (country: string) => {
  return countryCodes[country]
}

export const EuropeanUnionCountries: CountryCode[] = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

const EuropeCountries: CountryCode[] = [
  'AD',
  'AL',
  'AT',
  'AX',
  'BA',
  'BE',
  'BG',
  'BY',
  'CH',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FO',
  'FR',
  'GB',
  'GG',
  'GI',
  'GR',
  'HR',
  'HU',
  'IE',
  'IM',
  'IS',
  'IT',
  'JE',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SJ',
  'SK',
  'SM',
  'TR',
  'UA',
  'VA',
]

const EXCLUDED_COUNTRIES = ['AC', 'TA']

export const StoreEuropeCountries: CountryCode[] = [
  'AX',
  'AD',
  'AT',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FO',
  'FI',
  'FR',
  'DE',
  'GR',
  'GG',
  'HU',
  'IS',
  'IE',
  'IM',
  'IT',
  'JE',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MC',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'GB',
]

export const countries = getCountries()
  .filter((country) => !EXCLUDED_COUNTRIES.includes(country))
  .sort()

export const countryList = countries
  .map((code) => ({
    value: code,
    label: CountriesNames[code] || '',
  }))
  .sort((a, b) => (a.label < b.label ? -1 : 1))

// list of countries from https://developer.fedex.com/api/en-lv/catalog/address-validation/v1/docs.html
// "Countries/Territories Supporting Address Validation API" transformed to 2-letter country codes
export const FEDEX_ADDRESS_VALIDATION_COUNTRIES: CountryCode[] = [
  'CW',
  'BQ',
  'DK',
  'PA',
  'AR',
  'DO',
  'PE',
  'AW',
  'EE',
  'PT',
  'AU',
  'FI',
  'SG',
  'AT',
  'FR',
  'ZA',
  'BS',
  'DE',
  'ES',
  'BB',
  'GR',
  'SE',
  'BE',
  'GT',
  'CH',
  'BM',
  'HK',
  'TT',
  'BR',
  'IT',
  'GB',
  'CA',
  'JM',
  'US',
  'KY',
  'MY',
  'UY',
  'CL',
  'MX',
  'VE',
  'CO',
  'NL',
  'VI',
  'VI',
  'VG',
  'CR',
  'NZ',
  'CZ',
  'NO',
]
