import type { DeviceId, NetsuiteProductId } from './brands'
import type { Company } from './company'
import type { WarrantyStatus } from './enums'
import type { ProductParentCategory } from './products'

export type DeviceEntry = {
  id: DeviceId
  mac: string
  sku: string
  productId: NetsuiteProductId
}

export type Device = {
  id: DeviceId
  mac: string | null
  dateCode: string | null
  name: string
  sellerId: string | null
  coreItemCode: string | null
  warrantyStatus: WarrantyStatus
  isUnifiCare: boolean
  isUicareManual: boolean
  isHandledByUbiquiti: boolean
  isSoldByUbiquiti: boolean
  isSellerInactive: boolean
  isAdvancedFlowAvailable: boolean
  hasParent: boolean
  product: ProductParentCategory
  productImage: string
  seller: Company | null
  replacementParts: string[] | null
}

export enum KitType {
  device = 'device',
  accessory = 'accessory',
}

export type KitReturnProduct = {
  sku: string
  type: KitType
}

export type DeviceForwardType = 'forwarded-product' | 'forwarded-company'
