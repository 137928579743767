import { Radio, Text } from '@ubnt/ui-components'
import { useSubmitRmaStore } from '../submit-rma-store'

export function DevicePartsReturn({ sku, parts }: { sku: string; parts: string[] }) {
  const { selectedReplacementPart } = useSubmitRmaStore()

  return (
    <div className="flex column s-8">
      <Text size="body" weight="bold">
        Select the item you&apos;d like to replace.
      </Text>

      <div className="inline-flex">
        <Radio
          id="main-item"
          checked={!selectedReplacementPart}
          onChange={() =>
            useSubmitRmaStore.setState({
              selectedReplacementPart: null,
            })
          }
        >
          {sku}
        </Radio>
      </div>

      {parts.map((partSku) => (
        <div className="inline-flex">
          <Radio
            id={`part_${partSku}`}
            width={0}
            checked={selectedReplacementPart === partSku}
            onChange={() =>
              useSubmitRmaStore.setState({
                selectedReplacementPart: partSku,
              })
            }
          >
            {partSku} Only
          </Radio>
        </div>
      ))}
    </div>
  )
}
